function componentSelect() {
  var selects = document.querySelectorAll('.js-select')
  if (selects.length) {
    selects.forEach((select) => {
      var selectSearchable = select.getAttribute('data-searchable')
      var optionSearchable
      if (selectSearchable === '1') {
        optionSearchable = true
      } else {
        optionSearchable = false
      }
      var options = {
        searchable: optionSearchable,
      }
      if (select.getAttribute('data-timepicker') == 1) {
        window.timePicker = NiceSelect.bind(select, options)
      } else {
        NiceSelect.bind(select, options)
      }
    })
  }
}
