function componentTooltip() {
  function init() {
    var tooltips = document.querySelectorAll('.js-tooltip:not(.i-tooltip)')
    if (tooltips) {
      tippy('.js-tooltip:not(.i-tooltip)', {
        placement: 'bottom-end',
        animation: 'shift-away',
        content(reference) {
          // const id = reference.getAttribute('data-template');
          const id = reference.closest('.product__btn-block')
          const template = id.querySelector('.js-product-dropdown')
          return template.innerHTML
        },
        allowHTML: true,
        trigger: 'click',
        arrow: true,
        onShow: function(instance) {
          if (window.tooltipBind) {
            window.tooltipBind(instance.popper);
          }
        }
      })

      tooltips.forEach((tooltip) => {
        tooltip.classList.add('i-tooltip')
      })
    }
  }

  init()
  body.addEventListener('snippet-update', init)
}
