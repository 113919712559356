//====================================================
//  Function: Parts Detail
//====================================================
function partDetail() {
  var filterDetail = document.querySelector('.js-filter-detail')
  if (filterDetail) {
    windowWidth = window.innerWidth
    /*if (windowWidth < bpSmall) {
      document
        .querySelector('.tab-list__item.is-active')
        .classList.remove('is-active')
      document
        .querySelector('.tab-list__item[data-id="tab-detail-description"]')
        .classList.add('is-active')

      document
        .querySelector('.tab-content__item.is-active')
        .classList.remove('is-active')
      document
        .querySelector('.tab-content__item[data-id="tab-detail-description"]')
        .classList.add('is-active')
    }*/
  }
}
