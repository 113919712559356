//====================================================
//  Function: Add new address
//====================================================
function componentAddNewAddress() {
  var newAddress = document.querySelector('.js-add-new-address')
  if (newAddress) {
    document.addEventListener('click', function (e) {
      if (e.target.id === 'user-address-add-button') {
        document.querySelector('.js-add-new-address-content').style.display =
          'block'
        e.preventDefault()
      }
    })
  }

  var billingAddress = document.getElementById('billing-email')
  var billingAddressCheckbox = document.getElementById(
    'frm-billingForm-form-differentBillingEmail',
  )
  if (billingAddressCheckbox) {
    billingAddressCheckbox.addEventListener('change', function () {
      if (billingAddressCheckbox.checked) {
        billingAddress.style.display = 'block'
      } else {
        billingAddress.style.display = 'none'
      }
    })
  }

  var gdprHistoryCheckbox = document.querySelector('.gdpr-history-checkbox')
  if (gdprHistoryCheckbox) {
    // $(document).on('change', '.gdpr-history-checkbox', function () {
    //   if ($(this).prop('checked')) {
    //     $('.gdpr-history-table').removeClass('d-none')
    //   } else {
    //     $('.gdpr-history-table').addClass('d-none')
    //   }
    // })
  }
}
