// --- GLOBAL VARIABLES --- //
var bpSmall = 771
var bpMedium = 992
var bpLarge = 1200
var classIsActive = 'is-active'
var classIsDisabled = 'is-disabled'
var classIsLast = 'is-last'
var classHasError = 'has-error'
var classIsLoading = 'is-loading'
var classIsOpened = 'is-opened'
var classIsSelected = 'is-selected'
var classIsCollapse = 'is-collapse'
var classIsAnimated = 'is-animated'
var body = document.querySelector('body')
var document = document.querySelector('body')
var navigation = document.querySelector('.js-navigation')
// var $ = document.querySelector

function docReady(fn) {
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    setTimeout(fn, 1)
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

docReady(function () {
  // Modal
  componentModal()

  // Gallery
  componentGallery()

  // Select
  componentSelect()

  // Parameters
  componentParameters()

  // Rate
  componentRate()

  // Tabs
  componentTabs()

  // InputToggle
  componentInputToggle()

  // DataTable
  // componentDataTable()

  // Quantity Input
  componentQuantityInput()

  // BootstrapSlider
  componentBootstrapSlider()

  // Tooltip
  componentTooltip()

  // AddNewAddress
  componentAddNewAddress()

  // Dropdown
  componentDropdown()

  // Accordion
  componentAccordion()

  // Swiper
  componentSwiper()

  // Navigation
  componentFullscreenNavigation()

  // Filter
  componentFilter()

  // loadSkeleton
  componentloadSkeleton()

  // Configurator
  componentConfigurator()

  removeAllBreakLineProductAvailability()

  // partShowHidden
  partShowHidden()

  // partDetail
  partDetail()

  partScrollResize()
})

var resizeTimer

// --- SCROLL EVENT --- //
window.addEventListener('scroll', (event) => {
  if (resizeTimer) {
    window.cancelAnimationFrame(resizeTimer)
  }

  resizeTimer = window.requestAnimationFrame(function () {
    partScrollResize()
  })
})

// // --- LOAD EVENT --- //
window.addEventListener('load', (event) => {
  // Page Loader
  body.classList.add(classIsActive)
})
