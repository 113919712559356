function componentBootstrapSlider() {
  let sliderEx1 = document.getElementById('ex1')

  if (sliderEx1) {
    window.slider1 = new Slider(sliderEx1, {
      tooltip: 'always',
      tooltip_position: 'bottom',
      formatter: function (value) {
        return value + '%'
      },
    })
  }

  let sliderEx2 = document.getElementById('ex2')
  if (sliderEx2) {
    var mySlider = new Slider(sliderEx2, {
      tooltip: 'always',
      tooltip_position: 'bottom',
      formatter: function (value) {
        return value + '%'
      },
    })
  }
}
