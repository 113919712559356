//====================================================
//  Function: Accordion
//====================================================
function componentAccordion() {
  var accordionBlock = document.querySelector('.js-accordion-group')
  var accordionTrigger = document.querySelectorAll('.js-accordion-trigger')

  if (accordionBlock) {
    for (const trigger of accordionTrigger) {
      trigger.addEventListener('click', function (event) {
        var inst = trigger
        var thisAccordionItem = trigger.closest('.js-accordion-item')
        var thisBlock = trigger.closest('.js-accordion-group')
        var activeItem

        if (event.target.classList.contains('tooltip-icon')) {
          return false
        }

        if (thisAccordionItem.classList.contains('is-active')) {
          thisAccordionItem.classList.toggle('is-active')
        } else {
          activeItem = thisBlock.querySelectorAll(
            '.js-accordion-item.is-active',
          )
          if (
            activeItem.length > 0 &&
            inst.classList.contains('js-accordion-trigger-close')
          ) {
            for (const item of activeItem) {
              item.classList.remove('is-active')
            }
          }
          inst.closest('.js-accordion-item').classList.add('is-active')
        }
        return false
      })
    }
  }
}
