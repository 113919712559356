//====================================================
//  Function: RateYO
//====================================================
function componentRate() {
  var rateList = document.querySelector('.js-rate-list')
  if (rateList) {
    var starRatingControl = new StarRating('.star-rating', {
      maxStars: 5,
      tooltip: false,
      clearable: false,
    })

    rateList.addEventListener('change', function (e) {
      var inst = e.target
      var rateBlock = inst.closest('.js-rate')
      rateBlock.querySelector('.js-rate-input').value = this.value
    })

    var addReview = document.querySelector('#pridat-recenzi')
    addReview.style.display = 'none'

    document.addEventListener('click', function (event) {
      if (
        event.target.id == 'showAddRecenze' ||
        event.target.id == 'showAddRecenzeBtn'
      ) {
        document.getElementById('pridat-recenzi').style.display = 'block'
      }
    })
  }
}
