//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  heroSlider()
  benefitsSlider()
  productsSlider()
  blogSlider()
  gallerySlider()
}

function heroSlider() {
  var heroSliders = document.querySelectorAll('.js-slider-hero')
  if (window.innerWidth < 768) {
    if (heroSliders) {
      Array.prototype.forEach.call(heroSliders, function (el, i) {
        var inst = el,
          swiperBlock = inst.closest('.js-slider-group'),
          swiperPagination = swiperBlock.querySelector('.js-swiper-pagination')

        var mySwiper = new Swiper(inst, {
          spaceBetween: 0,
          lazy: true,
          speed: 600,
          loop: true,
          slidesPerView: 'auto',
          pagination: {
            el: swiperPagination,
            clickable: true,
          },
        })
      })
    }
  }
}

function benefitsSlider() {
  var benefitsSlider = document.querySelectorAll('.js-slider-benefits')

  if (benefitsSlider) {
    Array.prototype.forEach.call(benefitsSlider, function (el, i) {
      var inst = el,
        swiperBlock = inst.closest('.js-slider-group'),
        swiperArrowPrev = swiperBlock.querySelector('.js-swiper-arrow-prev'),
        swiperArrowNext = swiperBlock.querySelector('.js-swiper-arrow-next'),
        swiperSlidesCount = inst.dataset.count

      var mySwiper = new Swiper(inst, {
        slidesPerView: 1,
        spaceBetween: 20,
        lazy: true,
        speed: 600,
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: true,
        // },
        // pagination: {
        //   el: swiperPagination,
        //   clickable: true,
        // },
        navigation: {
          nextEl: swiperArrowNext,
          prevEl: swiperArrowPrev,
        },
        breakpoints: {
          200: {
            loop: false,
            slidesPerView: 2,
            // grid: {
            //   fill: 'rows',
            //   rows: 2,
            // },
          },
          576: {
            loop: true,
            slidesPerView: 2,
            // grid: {
            //   fill: 'column',
            //   rows: 1,
            // },
          },
          768: {
            loop: true,
            slidesPerView: 3,
            // grid: {
            //   fill: 'column',
            //   rows: 1,
            // },
          },
          992: {
            loop: true,
            slidesPerView: swiperSlidesCount,
            // grid: {
            //   fill: 'column',
            //   rows: 1,
            // },
          },
          1200: {
            loop: true,
            slidesPerView: swiperSlidesCount,
            // grid: {
            //   fill: 'column',
            //   rows: 1,
            // },
          },
        },
      })
    })
  }
}

function productsSlider() {
  var productsSliders = document.querySelectorAll('.js-slider-products')

  if (productsSliders) {
    Array.prototype.forEach.call(productsSliders, function (el, i) {
      var inst = el,
        swiperBlock = inst.closest('.js-slider-group'),
        swiperPagination = swiperBlock.querySelector('.js-swiper-pagination'),
        swiperArrowPrev = swiperBlock.querySelector('.js-swiper-arrow-prev'),
        swiperArrowNext = swiperBlock.querySelector('.js-swiper-arrow-next'),
        swiperSlidesCount = inst.dataset.count

      var mySwiper = new Swiper(inst, {
        slidesPerView: 1,
        spaceBetween: 0,
        lazy: true,
        speed: 600,
        navigation: {
          nextEl: swiperArrowNext,
          prevEl: swiperArrowPrev,
        },
        pagination: {
          el: swiperPagination,
          clickable: true,
        },
        breakpoints: {
          200: {
            slidesPerView: 'auto',
            loop: false,
            spaceBetween: 15,
          },
          576: {
            slidesPerView: 'auto',
            loop: false,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            loop: false,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 2,
            loop: false,
            spaceBetween: 0,
          },
          1200: {
            loop: false,
            spaceBetween: 0,
            slidesPerView: swiperSlidesCount,
          },
        },
      })
    })
  }
  var productsVisitedSliders = document.querySelectorAll('.js-slider-products-visited')

  if (productsVisitedSliders) {
    Array.prototype.forEach.call(productsVisitedSliders, function (el, i) {
      var inst = el,
        swiperBlock = inst.closest('.js-slider-group'),
        swiperPagination = swiperBlock.querySelector('.js-swiper-pagination'),
        swiperArrowPrev = swiperBlock.querySelector('.js-swiper-arrow-prev'),
        swiperArrowNext = swiperBlock.querySelector('.js-swiper-arrow-next'),
        swiperSlidesCount = inst.dataset.count

      var mySwiper = new Swiper(inst, {
        slidesPerView: 1,
        spaceBetween: 0,
        lazy: true,
        speed: 600,
        navigation: {
          nextEl: swiperArrowNext,
          prevEl: swiperArrowPrev,
        },
        pagination: {
          el: swiperPagination,
          clickable: true,
        },
        breakpoints: {
          200: {
            slidesPerView: 'auto',
            loop: false,
            spaceBetween: 15,
          },
          576: {
            slidesPerView: 'auto',
            loop: false,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            loop: false,
            spaceBetween: 0,
          },
          992: {
            slidesPerView: 3,
            loop: false,
            spaceBetween: 0,
          },
          1200: {
            loop: false,
            spaceBetween: 0,
            slidesPerView: swiperSlidesCount,
          },
        },
      })
    })
  }
}

function blogSlider() {
  var blogsSliders = document.querySelectorAll('.js-slider-blog')
  if (window.innerWidth < 768) {
    if (blogsSliders) {
      Array.prototype.forEach.call(blogsSliders, function (el, i) {
        var inst = el,
          swiperBlock = inst.closest('.js-slider-group'),
          swiperPagination = swiperBlock.querySelector('.js-swiper-pagination'),
          swiperArrowPrev = swiperBlock.querySelector('.js-swiper-arrow-prev'),
          swiperArrowNext = swiperBlock.querySelector('.js-swiper-arrow-next')

        var mySwiper = new Swiper(inst, {
          spaceBetween: 15,
          lazy: true,
          speed: 600,
          loop: false,
          slidesPerView: 'auto',
          navigation: {
            nextEl: swiperArrowNext,
            prevEl: swiperArrowPrev,
          },
          pagination: {
            el: swiperPagination,
            clickable: true,
          },
        })
      })
    }
  }
}

function gallerySlider() {
  var gallerySliders = document.querySelectorAll('.js-slider-gallery')
  if (gallerySliders) {
    Array.prototype.forEach.call(gallerySliders, function (el, i) {
      var inst = el,
        swiperBlock = inst.closest('.js-slider-group'),
        swiperArrowPrev = swiperBlock.querySelector('.js-swiper-arrow-prev'),
        swiperArrowNext = swiperBlock.querySelector('.js-swiper-arrow-next')
      // swiperPagination = swiperBlock.querySelector('.js-swiper-pagination')

      var mySwiper = new Swiper(inst, {
        spaceBetween: 0,
        lazy: true,
        speed: 600,
        loop: true,
        slidesPerView: 1,
        navigation: {
          nextEl: swiperArrowNext,
          prevEl: swiperArrowPrev,
        },
      })
    })
  }
}
