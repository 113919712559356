//====================================================
//  Function: Gallery
//====================================================
var galleries
function componentGallery() {
  galleries = document.querySelectorAll('.js-gallery')
  if (galleries.length) {

    galleries.forEach((gallery) => {
      lightGallery(gallery, {
        selector: '.js-gallery-item',
        hash: false,
        zoom: false,
        autoplay: false,
        autoplayFirstVideo: false,
        thumbnail: false,
        rotate: false,
        share: false,
        share: false,
        fullScreen: false,
        download: false,
      })
    })
  }
}