var categoryList
var categoryListSkeleton

function componentloadSkeleton() {
  categoryList = document.querySelector('.js-category-list')
  categoryListSkeleton = document.querySelector('.js-category-placeholder')
}

// call skeleton
function loadSkeleton() {
  var productPlaceholderItem = categoryListSkeleton.querySelector(
    '.is-product-skeleton',
  )
  if (!productPlaceholderItem) {
    var getLastProductItem = categoryList.querySelector(
      '.product__item--list-side:first-child',
    )

    var divClone
    for (var i = 0; i < 6; i++) {
      divClone = getLastProductItem.cloneNode(true)
      divClone.classList.add('is-product-skeleton')
      categoryListSkeleton.appendChild(divClone)
    }
  } else {
    categoryListSkeleton.classList.add('d-flex')
    categoryListSkeleton.classList.remove('d-none')
  }
}

// remove skeleton
function finishResponseSkeleton() {
  categoryListSkeleton.classList.add('d-none')
}

function decolorProducts() {
  var getProductItems = categoryList.querySelectorAll('.product__item')
  getProductItems.forEach((productItem) => {
    productItem.classList.add('is-product-grey')
  })
}

function removeDecolorProducts() {
  var getProductItems = categoryList.querySelectorAll('.product__item')
  getProductItems.forEach((productItem) => {
    productItem.classList.remove('is-product-grey')
  })
}
