function componentDataTable() {
  let app = window.app || {}
  app.dt = app.dt || {}

  app.dt.i18n_sources = {
    cs: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Czech.json',
    sk: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Slovak.json',
    hu: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Hungarian.json',
    ro: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Romanian.json',
  }

  $.fn.dataTable.ext.order.intl()
  $.fn.dataTable.moment('d. M. YYYY')

  window.app = app

  var tableClassic = document.querySelector('.js-table-classic')
  if (tableClassic) {
    let $table = $('#datatable--list'),
      locale = $table.data('locale'),
      rowCount = $table.find('.datable__row').length,
      paging = false

    if (rowCount > 10) {
      paging = true
    }

    let datatableSettings = {
      columnDefs: [
        {
          targets: [0],
          type: 'num',
        },
        {
          targets: [1],
          orderData: [1, 0],
        },
        {
          targets: [2],
          orderData: [2, 0],
        },
        {
          targets: [3],
          type: 'num',
          orderData: [3, 0],
        },
      ],
      columns: [
        { orderable: true },
        { orderable: true },
        { orderable: true },
        { orderable: true },
        { orderable: false },
        { orderable: false },
      ],
      order: [[0, 'desc']],
      responsive: true,
      paging: paging,
      lengthChange: false,
      pagingType: 'simple_numbers',
      language: {
        url: window.app.dt.i18n_sources[locale],
        decimal: ',',
        thousands: ' ',
      },
    }

    $table.DataTable(datatableSettings)
  }

  var tableOrders = document.querySelector('.js-table-orders')
  if (tableOrders) {
    let $table = $('#datatable--list'),
      locale = $table.data('locale'),
      rowCount = $table.find('.datable__row').length,
      paging = false

    if (rowCount > 10) {
      paging = true
    }

    let datatableSettings = {
      language: {
        url: window.app.dt.i18n_sources[locale],
        decimal: ',',
        thousands: ' ',
      },
      columnDefs: [{ targets: [5], type: 'num' }],
      order: [[0, 'desc']],
      responsive: true,
      pagingType: 'simple_numbers',
      lengthChange: false,
      columns: [
        { orderable: true },
        { orderable: true },
        { orderable: true },
        { orderable: true },
        { orderable: true },
        { orderable: true },
        { orderable: false },
      ],
    }

    $table.DataTable(datatableSettings)
  }
}
