function componentInputToggle() {
  function init() {
    var toggleInput = document.querySelector('.js-toggle-input');

    if (toggleInput) {
      var inputs = document.querySelectorAll('input[type="radio"]:not(.i-toggle)')

      inputs.forEach((input) => {
        input.classList.add('i-toggle');
        input.addEventListener('change', checkToggleInputs);
      })
    }
  }

  function checkToggleInputs() {
    var inputs = document.querySelectorAll('.js-toggle-input');

    inputs.forEach((input) => {
      var toggleDest = document.querySelector('.' + input.getAttribute('data-toggle'));
      var toggleInput = document.querySelector('.' + input.getAttribute('data-toggle-input'));

      if (toggleDest) {
        toggleDest.style.display = input.checked ? 'block' : 'none';
      }

      if (toggleInput && !input.checked) {
        toggleInput.checked = false;
      }
    })
  }

  init()
  checkToggleInputs()
  body.addEventListener('snippet-update', init)
}