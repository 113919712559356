//====================================================
//  Function: Fullscreen navigation
//====================================================
function componentFullscreenNavigation() {
  if (navigation) {
    var navigationTrigger = document.querySelector('.js-navigation-trigger')
    navigationTrigger.addEventListener(
      'click',
      function (event) {
        event.preventDefault()
        event.target.closest('.js-navigation').classList.toggle(classIsCollapse)
        body.classList.toggle('is-navigation-open')

        var dropdownMenuNavs = document.querySelectorAll(
          '.main-nav__item.js-dropdown-block',
        )
        if (dropdownMenuNavs.length) {
          dropdownMenuNavs.forEach((dropdownMenuNav) => {
            dropdownMenuNav.classList.remove(classIsActive)
          })
        }

        if (!event.target.closest('.js-navigation').classList.contains(classIsCollapse)) {
          var sumenuOpen = document.querySelector('.main-nav.is-submenu-open');

          if (sumenuOpen) {
            sumenuOpen.classList.remove('is-submenu-open');
          }
        }
      },
      false,
    )

    checkNavigationTrigger()
  }
}

function checkNavigationTrigger() {
  // if (
  //   $('.js-navigation').hasClass(classIsCollapse) &&
  //   $(window).width() > bp_medium
  // ) {
  //   $('.js-navigation').removeClass(classIsCollapse)
  // }
}
