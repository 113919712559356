//====================================================
//  Function: Tabs
//====================================================
function componentTabs() {
  var tabsGroup = document.querySelectorAll('.js-tabs-group')
  if (tabsGroup.length) {
    var tabsListItem = document.querySelectorAll('.js-tab-list-item')
    tabsListItem.forEach((tab) => {
      tab.addEventListener(
        'click',
        function (el) {
          var inst = el.target
          var thisId = inst.dataset.id
          var thisTabs = inst.closest('.js-tabs-group')
          if (thisId == 'undefined') {
            return false
          }

          if (!inst.classList.contains(classIsActive)) {
            thisTabs
              .querySelector('.js-tab-list-item.' + classIsActive + '')
              .classList.remove(classIsActive)
            inst.closest('.js-tab-list-item').classList.add(classIsActive)

            var filterHeader = thisTabs.querySelector(
              '.filter-header .js-tabs-content .js-tab-content-item.' +
                classIsActive +
                '',
            )
            if (filterHeader) {
              thisTabs
                .querySelector(
                  '.filter-header .js-tabs-content .js-tab-content-item.' +
                    classIsActive +
                    '',
                )
                .classList.remove(classIsActive)

              thisTabs
                .querySelector(
                  '.filter-header .js-tabs-content .js-tab-content-item[data-id="' +
                    thisId +
                    '"]',
                )
                .classList.add(classIsActive)
            }

            thisTabs
              .querySelector(
                '.tabs-content.js-tabs-content .js-tab-content-item.' +
                  classIsActive +
                  '',
              )
              .classList.remove(classIsActive)

            thisTabs
              .querySelector(
                '.tabs-content.js-tabs-content .js-tab-content-item[data-id="' +
                  thisId +
                  '"]',
              )
              .classList.add(classIsActive)

            if (
              inst.classList.contains('js-tab-list-item-close-after-select')
            ) {
              var elementSelected = document.querySelector(
                '.js-hidden-content[data-content="user-menu"]',
              )
              elementSelected.classList.toggle(classIsActive)
            }
          }
        },
        false,
      )
    })
  }
}
