//====================================================
//  Function: Scroll & resize events
//====================================================
var windowScrollTop
var windowWidth
// var bottomTop;
// var bottomHeight;

var navigation = document.querySelector('.js-navigation')

// if ($('#bottom-bar').length){
// 	var bottomBar = $('#bottom-bar');
// }

const content = document.querySelector('body')

function partScrollResize() {
  windowScrollTop = document.scrollingElement.scrollTop
  windowWidth = window.innerWidth

  if (navigation) {
    if (windowScrollTop > 60 && windowWidth < bpSmall) {
      navigation.classList.add('is-scrolling')
      fn_secondary_header_auto_hide(windowScrollTop)
    } else {
      navigation.classList.remove('is-scrolling')
      navigation.classList.remove('navigation-scroll-bottom')
    }
  }

  // if ($('#bottom-bar').length && windowWidth < bp_medium){
  // 	var bottomTop = $('.content-offset-top').offset().top;
  // 	var bottomHeight = $('.content-height').outerHeight();
  // 	if ((windowScrollTop > bottomTop - 60 ) && (windowScrollTop < bottomHeight - 60) ) {
  // 		bottomBar.addClass( classIsActive );
  // 	} else {
  // 		bottomBar.removeClass( classIsActive );
  // 	}
  // }
}

var actualTop = 0
var upTotal = 0
var scrollBottom = 0

function fn_secondary_header_auto_hide(windowScrollTop) {
  if (actualTop == 0 || actualTop == undefined) {
    actualTop = windowScrollTop
  } else {
    if (actualTop < windowScrollTop) {
      //Dole
      upTotal = 0
      navigation.classList.add('navigation-scroll-bottom')
      document.querySelector('body').classList.remove('is-active-search')
      if (
        document.querySelector(
          '.js-hidden-content.is-active[data-content="header-search"]',
        )
      ) {
        document
          .querySelector(
            '.js-hidden-content.is-active[data-content="header-search"]',
          )
          .classList.remove('is-active')
      }
      actualTop = windowScrollTop
    } else {
      //Hore
      upTotal += actualTop - windowScrollTop
      if (upTotal >= 300) {
        navigation.classList.remove('navigation-scroll-bottom')
      }

      actualTop = windowScrollTop
    }
  }
}
