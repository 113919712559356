//====================================================
//  Function: Configurator
//====================================================
function componentConfigurator() {
  var resetHdd = document.querySelector('.server-hdd-reset')
  var hddItems = document.querySelectorAll('.server-hdd')
  var hddQuantities = document.querySelectorAll('.server-hdd-quantity')

  if (resetHdd) {
    resetHdd.addEventListener('change', function () {
      hddItems.forEach((hddItem) => {
        hddItem.checked = false
        var hddBlock = hddItem.closest('.config__checkbox')
        hddBlock.querySelector('.quantity__input').value = 0
      })
    })
  }

  if (hddItems.length) {
    hddItems.forEach((hddItem) => {
      var wrapper = hddItem.closest('.config__checkbox')
      var hddQuantity = wrapper.querySelector('.server-hdd-quantity')

      hddItem.addEventListener('change', function () {
        if (this.checked) {
          hddQuantity.value = 1

          if (checkItem(hddQuantity)) {
            resetHdd.checked = false
          }
          else {
            hddQuantity.value = 0
            this.checked = false
          }
        }
        else {
          hddQuantity.value = 0
          checkQuantityBlock()
        }
      })
    })
  }

  if (hddQuantities.length) {
    hddQuantities.forEach((hddQuantity) => {
      var wrapper = hddQuantity.closest('.config__checkbox')
      var hddItem = wrapper.querySelector('.server-hdd')

      hddQuantity.setAttribute('data-prev-value', hddQuantity.value)

      hddQuantity.addEventListener('change', function () {
        if (this.value > 0) {
          if (checkItem(this)) {
            resetHdd.checked = false
            hddItem.checked = true
          }
        }
        else if (this.value == 0) {
          hddItem.checked = false

          checkQuantityBlock()
        }
      })
    })
  }

  function checkItem(input) {
    var totalQuantity = getQuantity();
    var totalMax = parseInt(input.getAttribute('data-max'), 10)
    var inputQuantity = parseInt(input.value, 10)
    var inputMax = parseInt(input.getAttribute('max'), 10)

    if (totalQuantity <= totalMax && inputQuantity <= inputMax) {
      input.setAttribute('data-prev-value', input.value)
      return true
    }

    input.value = input.getAttribute('data-prev-value')
    return false
  }

  function getQuantity() {
    var quantity = 0

    hddQuantities.forEach((hddQuantity) => {
      quantity += parseInt(hddQuantity.value, 10)
    })

    return quantity
  }

  function checkQuantityBlock() {
    if (!getQuantity()) {
      resetHdd.checked = true
    }
  }
}
