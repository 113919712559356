//====================================================
//  Function: Parameters
//====================================================
function componentParameters() {
  var parametersBlock = document.querySelector('.js-parameters')

  if (parametersBlock) {
    var parametersItems = document.querySelectorAll('.js-parameter-item')
    if (parametersItems.length) {
      ;[].forEach.call(parametersItems, function (parameterItem) {
        var inst = parameterItem
        if (inst.classList.contains('parameters__title--open')) {
          inst.querySelector('.parameters__hidden').style.display = ''
        } else {
          inst.querySelector('.parameters__hidden').style.display = 'none'
        }
        parameterTrigger = inst.querySelector('.parameters__title')
        parameterTrigger.addEventListener(
          'click',
          function (el) {
            var instItem = el.target
            if (instItem.classList.contains('parameters__title--open')) {
              instItem.parentNode.querySelector(
                '.parameters__hidden',
              ).style.display = 'none'
              instItem.classList.remove('parameters__title--open')
            } else {
              instItem.parentNode.querySelector(
                '.parameters__hidden',
              ).style.display = ''
              instItem.classList.add('parameters__title--open')
            }
          },
          false,
        )
      })
    }

    var parametersToggleAll = document.querySelector(
      '#toggleAllParametersButton',
    )

    parametersToggleAll.addEventListener(
      'click',
      function (el) {
        let button = el.target
        let html = button.innerHTML.trim()

        if (html === button.dataset.hideLabel.trim()) {
          button.innerHTML = button.dataset.showLabel.trim()
          var parametersItemsAll = document.querySelectorAll(
            '.parameters__title--open',
          )

          parametersItemsAll.forEach((parameterItem) => {
            parameterItem.click()
          })
          return
        }

        if (html === button.dataset.showLabel.trim()) {
          button.innerHTML = button.dataset.hideLabel.trim()

          var parametersItemsAll = document.querySelectorAll(
            '.parameters__title',
          )

          parametersItemsAll.forEach((parameterItem) => {
            if (parameterItem.classList.contains('parameters__title--open')) {
              return
            }
            parameterItem.click()
          })
        }
      },
      false,
    )
  }
}
