//====================================================
//  Function: Dropdown
//====================================================
function componentDropdown() {
  function init() {
    var dropdownTrigger = document.querySelectorAll('.js-dropdown-trigger:not(.i-dropdown)')
    if (dropdownTrigger.length) {
      dropdownTrigger.forEach((dropdownToggle) => {
        dropdownToggle.classList.add('i-dropdown')

        dropdownToggle.addEventListener(
          'click',
          function (el) {
            var inst = el.target

            if (
              inst.classList.contains('filter-subcategory__title') ||
              inst.classList.contains('submenu-back--filter')
            ) {
              inst.closest('.js-dropdown-block').classList.toggle(classIsActive)
              inst
                .closest('.js-filter')
                .classList.toggle('is-filter-subcategory-open')
              return false
            }

            if (window.innerWidth < bpSmall) {
              inst.closest('.js-dropdown-block').classList.toggle(classIsActive)
            }

            if (
              inst.classList.contains('main-nav__link') ||
              inst.classList.contains('submenu-back--submenu')
            ) {
              // inst.closest('.js-dropdown-block').classList.toggle(classIsActive)
              inst.closest('.main-nav').classList.toggle('is-submenu-open')
            }
          },
          false,
        )
      })
    }

    var searchInputHeader = document.querySelector('.header .form__input--search:not(.i-dropdown)')

    if (searchInputHeader) {
      searchInputHeader.classList.add('i-dropdown')

      searchInputHeader.addEventListener(
        'focus',
        function (el) {
          if (window.innerWidth < bpSmall) {
            var elementSelected = document.querySelector(
              '.js-hidden-content[data-content="header-search"]',
            )
            elementSelected.classList.toggle(classIsActive)
            body.classList.toggle('is-active-search')
          }
        },
        false,
      )
    }

    var dropdownOfClickedUser = document.querySelector('.js-dropdown-user:not(.i-dropdown)')

    if (dropdownOfClickedUser) {
      dropdownOfClickedUser.classList.add('i-dropdown')

      document.addEventListener(
        'click',
        function (event) {
          var isClickInsideElement = dropdownOfClickedUser.contains(event.target)
          if (!isClickInsideElement) {
            dropdownOfClickedUser.classList.remove(classIsActive)
          } else {
            body.classList.remove('is-active-search')
            body.classList.remove('is-navigation-open')
            navigation.classList.remove(classIsCollapse)
          }
        },
        false,
      )
    }

    var dropdownOfClickedSearch = document.querySelector('.js-dropdown-search:not(.i-dropdown)')

    if (dropdownOfClickedSearch) {
      dropdownOfClickedSearch.classList.add('i-dropdown')

      document.addEventListener(
        'click',
        function (event) {
          var isClickInsideElement = dropdownOfClickedSearch.contains(
            event.target,
          )
          if (!isClickInsideElement) {
            dropdownOfClickedSearch.classList.remove(classIsActive)
          }
        },
        false,
      )
    }
  }

  init()
  body.addEventListener('snippet-update', init)
}
