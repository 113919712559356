//====================================================
//  Function: Filter
//====================================================
function componentFilter() {
  var filter = document.querySelector('.js-filter')
  if (filter) {
    var filterTrigger = document.querySelectorAll('.js-filter-trigger')
    filterTrigger.forEach((filterItem) => {
      filterItem.addEventListener(
        'click',
        function (el) {
          filter.classList.toggle(classIsActive)
          body.classList.toggle('is-filter-open')

          var dropdownFilterNavs = document.querySelectorAll(
            '.js-filter .accordion__item.js-accordion-item',
          )

          if (dropdownFilterNavs.length) {
            dropdownFilterNavs.forEach((dropdownMenuNav) => {
              dropdownMenuNav.classList.remove(classIsActive)
            })
          }
          el.preventDefault()
        },
        false,
      )
    })
  }

  body.addEventListener('open-modal-modal-filter', filterModalOpen);
}

function filterModalOpen() {
  var modal = document.querySelector('.js-modal[data-modal="modal-filter"]')
  var tabContents = modal.querySelectorAll('.tab-content__item')
  var tabWrapper = modal.querySelector('.tabs-content')
  var max = 0

  Array.prototype.forEach.call(tabContents, (tabContent, k) => {
    tabContent.classList.add('is-active');
    var height = tabContent.getBoundingClientRect().height

    if (height > max) {
      max = height
    }

    if (k) {
      tabContent.classList.remove('is-active');
    }
  })

  if (max > window.innerHeight * 0.7) {
    tabWrapper.style.height = `${window.innerHeight * 0.7}px`
  }
  else {
    tabWrapper.style.height = `${max + 50}px`
  }

  tabWrapper.style.overflowX = `hidden`
  tabWrapper.style.overflowY = `auto`
}
