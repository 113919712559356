//====================================================
//  Function: Modal
//====================================================
function componentModal() {
  function init() {
    var modalTriggers = document.querySelectorAll('.js-modal-trigger:not(.i-modal)')

    if (modalTriggers.length) {
      // open modal
      modalTriggers.forEach((modalTrigger) => {
        modalTrigger.classList.add('i-modal')

        modalTrigger.addEventListener(
          'click',
          function (el) {
            var inst = el.target
            var modalId = inst.getAttribute('data-id')
            openModal(modalId, inst)
          },
          false,
        )
      })
    }

    var modalsClose = document.querySelectorAll('.js-close-modal:not(.i-modal)')

    modalsClose.forEach((modalClose) => {
      modalClose.classList.add('i-modal')

      modalClose.addEventListener(
        'click',
        function () {
          closeModals()
          return false
        },
        false,
      )
    })

    var modalOverlay = document.querySelectorAll('.js-modal-overlay:not(.i-modal)')

    modalOverlay.forEach((modalOverlayItem) => {
      modalOverlayItem.classList.add('i-modal')

      modalOverlayItem.addEventListener(
        'click',
        function () {
          closeModals()
          body.classList.remove('is-active-search')
          body.classList.remove('is-navigation-open')
          navigation.classList.remove(classIsCollapse)
          var headerSearchContent = document.querySelector(
            '.js-hidden-content.is-active[data-content="header-search"]',
          )
          if (headerSearchContent) {
            headerSearchContent.classList.remove('is-active')
          }

          body.classList.remove('is-filter-open')
          var filter = document.querySelector('.js-filter')
          if (filter) {
            filter.classList.remove('is-active')
          }

          return false
        },
        false,
      )
    })
  }

  // close modal keypress key escape
  document.addEventListener(
    'keydown',
    function (event) {
      if (
        event.keyCode == 27 &&
        document.querySelector('.js-modal.is-active')
      ) {
        closeModals()
      }
    },
    false,
  )

  document.addEventListener(
    'click',
    function (event) {
      if (!event.target.closest('.js-modal-body')) {
        // closeModals()
      }
    },
    false,
  )

  init()
  body.addEventListener('snippet-update', init)
}

// function openModal(id, target) {
function openModal(id, target) {
  var modalId = id
  // var modalPrice = target.dataset.price
  var modalsActive = document.querySelectorAll('.js-modal.is-active')

  if (modalsActive.length) {
    document
      .querySelector('.js-modal.' + classIsActive + '')
      .classList.add('modal-hidden')
  } else {
    document.querySelector('.js-modal-overlay').classList.add(classIsActive)
  }
  document
    .querySelector('.js-modal[data-modal=' + modalId + ']')
    .classList.add(classIsActive)

  var activeModal = document.querySelector(
    '.js-modal[data-modal=' + modalId + ']',
  )
  if (activeModal.classList.contains('js-simple-modal')) {
    var contentHeader = target.dataset.title
    var contentBody = target.dataset.body
    document.querySelector(
      '.js-modal.is-active .js-modal-header',
    ).innerHTML = contentHeader
    document.querySelector(
      '.js-modal.is-active .js-modal-content',
    ).innerHTML = contentBody
  }

  /*if (modalPrice !== undefined) {
    var itemAmountId = target.dataset.amountId
    var itemAmount = target.dataset.amount
    var itemPrice = target.dataset.price
    var itemPriceTotal = parseInt(itemPrice) * parseInt(itemAmount)

    document.querySelector(
      '.js-modal.is-active .js-sale-amount-id',
    ).innerHTML = itemAmountId
    document.querySelector(
      '.js-modal.is-active .js-sale-amount',
    ).innerHTML = itemAmount
    document.querySelector(
      '.js-modal.is-active .js-sale-price',
    ).innerHTML = itemPrice
    document.querySelector(
      '.js-modal.is-active .js-sale-price-total',
    ).innerHTML = itemPriceTotal
  }*/

  var closer = activeModal.querySelector('.modal__closer')

  if (!closer) {
    closer = document.createElement('div')
    closer.classList.add('modal__closer')
    activeModal.appendChild(closer)

    closer.addEventListener(
      'click',
      closeModals,
      false,
    )
  }

  body.classList.add('is-modal-open')
  var event = new CustomEvent(`open-modal-${modalId}`, {})
  body.dispatchEvent(event)
  return false
}

function closeModals() {
  var modalsHidden = document.querySelectorAll('.modal-hidden')
  var modalActive = document.querySelectorAll('.js-modal.is-active')
  if (modalsHidden.length) {
    document
      .querySelector('.js-modal.is-active:not(.modal-hidden)')
      .classList.remove(classIsActive)
    const modalsHiddenArray = Array.from(
      document.getElementsByClassName('modal-hidden'),
    )

    modalsHiddenArray.forEach((box) => {
      box.classList.remove('modal-hidden')
    })

    return false
  } else {
    document.querySelector('.js-modal-overlay').classList.remove(classIsActive)
  }
  if (modalActive) {
    const modalsIsActive = Array.from(
      document.querySelectorAll('.js-modal.is-active'),
    )

    modalsIsActive.forEach((modalActive) => {
      modalActive.classList.remove(classIsActive)
    })

    // document
    //   .querySelector('.js-modal.' + classIsActive + '')
    //   .classList.remove(classIsActive)
  }

  body.classList.remove('is-modal-open')
}
