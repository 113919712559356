//====================================================
//  Function: Show/hidden
//====================================================
function partShowHidden() {
  var activeToggles = document.querySelectorAll('.js-active-content-toggle')
  if (activeToggles.length) {
    activeToggles.forEach((activeToggle) => {
      activeToggle.addEventListener(
        'click',
        function (el) {
          var inst = el.target
          var contentId = inst.dataset.content
          var elementSelected = document.querySelector(
            '.js-hidden-content[data-content="' + contentId + '"]',
          )
          elementSelected.classList.toggle(classIsActive)

          if (contentId === 'header-search') {
            body.classList.toggle('is-active-search')
          }

          el.preventDefault()
        },
        false,
      )
    })
  }

  var moreToggle = document.querySelectorAll('.jsButtonVice')

  if (moreToggle.length) {
    moreToggle.forEach((more) => {
      more.addEventListener(
        'click',
        function (el) {
          var inst = el.target
          var instBlock = inst.closest('.js-button-vice-block')
          var instTextMax = instBlock.querySelector('.text--max')
          instTextMax.classList.toggle('text--max--open')

          if (instTextMax.classList.contains('text--max--open')) {
            var instAttr = inst.dataset.less
            inst.innerHTML = instAttr
          } else {
            var instAttr = inst.dataset.more
            inst.innerHTML = instAttr
          }
        },
        false,
      )
    })
  }

  // var elementOfClicked = '.dropdown-basket'
  // $(document).on('click', function (event) {
  //   if (!$(event.target).closest(elementOfClicked).length) {
  //     $(elementOfClicked + '.is-active').removeClass(classIsActive)
  //   }
  // })
}
